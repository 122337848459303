@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;700&family=Inter:wght@300;400;500;600;700&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

:root {
  --bg-primary: #ffffff;
  --bg-secondary: #f0f0f0;
  --text-primary: #222629;
  --text-secondary: #474B4F;
  --accent-primary: #61892F;
  --accent-secondary: #86C232;
}

[data-theme="dark"] {
  --bg-primary: #222629;
  --bg-secondary: #474B4F;
  --text-primary: #ffffff;
  --text-secondary: #6B6E70;
  --accent-primary: #86C232;
  --accent-secondary: #61892F;
}

body {
  font-family: 'Inter', sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.6;
  overflow-x: hidden;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'JetBrains Mono', monospace;
  color: var(--accent-primary);
  margin-bottom: 1rem;
  position: relative;
}

h1 svg,
h2 svg,
h3 svg {
  color: var(--accent-secondary);
}

.text-gradient {
  background: linear-gradient(45deg, var(--accent-primary), var(--accent-secondary));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
}

.btn {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  padding: 12px 24px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border: none;
  border-radius: 4px;
}

.btn:hover {
  background-color: var(--accent-secondary);
}

.card {
  background-color: var(--bg-secondary);
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.input {
  width: 100%;
  padding: 12px;
  background-color: var(--bg-secondary);
  border: 1px solid var(--text-secondary);
  color: var(--text-primary);
  font-family: 'JetBrains Mono', monospace;
  transition: all 0.3s ease;
  border-radius: 4px;
}

.input:focus {
  outline: none;
  border-color: var(--accent-primary);
  box-shadow: 0 0 0 2px rgba(97, 137, 47, 0.2);
}

.grid {
  display: grid;
  gap: 20px;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 768px) {
  .grid-cols-2 {
    grid-template-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.header {
  background-color: var(--bg-secondary);
  padding: 20px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.nav-link {
  color: var(--text-primary);
  text-decoration: none;
  margin: 0 15px;
  transition: all 0.3s ease;
  position: relative;
  font-weight: 500;
  font-size: 14px;
}

.nav-link:hover {
  color: var(--accent-primary);
}

.footer {
  background-color: var(--bg-secondary);
  color: var(--text-secondary);
  text-align: center;
  padding: 20px 0;
  position: relative;
}

.profile-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: 4px solid var(--accent-primary);
  overflow: hidden;
}

.profile-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.3s ease;
}

.profile-image:hover img {
  filter: brightness(1.1);
}

.skill-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.skill-item {
  background-color: var(--bg-secondary);
  padding: 15px;
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.skill-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.project-card {
  padding: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
  background-color: var(--bg-secondary);
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.project-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.timeline {
  position: relative;
  padding-left: 30px;
}

.timeline::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 2px;
  background: linear-gradient(to bottom, var(--accent-primary), var(--accent-secondary));
}

.timeline-item {
  position: relative;
  margin-bottom: 30px;
  padding: 15px;
  background-color: var(--bg-secondary);
  border-radius: 8px;
}

.timeline-item::before {
  content: '';
  position: absolute;
  left: -36px;
  top: 15px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--accent-primary);
  box-shadow: 0 0 0 4px var(--bg-primary);
}

.blockchain-visualization {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  overflow-x: auto;
  padding-bottom: 20px;
}

.block {
  min-width: 120px;
  height: 120px;
  background-color: var(--bg-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.8rem;
  color: var(--text-secondary);
  position: relative;
  margin-right: 40px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  transition: all 0.3s ease;
}

.block:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.block::after {
  content: '→';
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  color: var(--accent-primary);
  font-size: 1.2rem;
}

.block:last-child::after {
  display: none;
}

.game-dev-showcase {
  position: relative;
  padding: 30px;
  background-color: var(--bg-secondary);
  border-radius: 10px;
  overflow: hidden;
  margin-bottom: 30px;
}

.game-character {
  width: 100px;
  height: 100px;
  background-color: var(--accent-primary);
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  margin: 0 auto;
  animation: float 3s ease-in-out infinite;
}

@keyframes float {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}

.game-character::before {
  content: '';
  position: absolute;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  background-color: var(--bg-primary);
  border-radius: 50%;
}

.game-character::after {
  content: '';
  position: absolute;
  bottom: 20%;
  left: 45%;
  width: 10%;
  height: 10%;
  background-color: var(--bg-primary);
  border-radius: 50%;
}

.code-editor {
  background-color: var(--bg-secondary);
  padding: 20px;
  border-radius: 8px;
  font-family: 'JetBrains Mono', monospace;
  color: var(--text-primary);
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.code-editor::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 25px;
  background-color: rgba(255, 255, 255, 0.1);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.code-editor::after {
  content: '• • •';
  position: absolute;
  top: 5px;
  left: 10px;
  color: var(--text-secondary);
  font-size: 20px;
  letter-spacing: 2px;
}

.code-line {
  display: block;
  margin-bottom: 5px;
}

.code-comment {
  color: var(--text-secondary);
}

.code-keyword {
  color: var(--accent-secondary);
}

.code-string {
  color: var(--accent-primary);
}

.code-function {
  color: var(--accent-primary);
}

.tech-stack {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: center;
  margin-bottom: 30px;
}

.tech-item {
  background-color: var(--bg-secondary);
  padding: 10px 20px;
  border-radius: 20px;
  font-family: 'JetBrains Mono', monospace;
  font-size: 0.9rem;
  color: var(--text-primary);
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.tech-item:hover {
  transform: translateY(-3px) rotate(3deg);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  color: var(--accent-primary);
}

.animated-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0.05;
}

.animated-bg span {
  position: absolute;
  display: block;
  width: 20px;
  height: 20px;
  background-color: var(--accent-primary);
  animation: move 25s infinite alternate;
}

.animated-bg span:nth-child(2) {
  background-color: var(--accent-secondary);
  animation-duration: 30s;
  animation-delay: -2s;
}

.animated-bg span:nth-child(3) {
  background-color: var(--text-primary);
  animation-duration: 35s;
  animation-delay: -4s;
}

@keyframes move {
  0% {
    transform: translate(-10vw, -10vh) rotate(0deg);
  }

  100% {
    transform: translate(110vw, 110vh) rotate(720deg);
  }
}

.glitch-effect {
  position: relative;
  display: inline-block;
}

.glitch-effect::before,
.glitch-effect::after {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.glitch-effect::before {
  left: 2px;
  text-shadow: -1px 0 var(--accent-secondary);
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}

.glitch-effect::after {
  left: -2px;
  text-shadow: -1px 0 var(--accent-primary);
  clip: rect(44px, 450px, 56px, 0);
  animation: glitch-anim 5s infinite linear alternate-reverse;
}

@keyframes glitch-anim {
  0% {
    clip: rect(31px, 9999px, 94px, 0);
  }

  10% {
    clip: rect(112px, 9999px, 76px, 0);
  }

  20% {
    clip: rect(85px, 9999px, 77px, 0);
  }

  30% {
    clip: rect(27px, 9999px, 97px, 0);
  }

  40% {
    clip: rect(64px, 9999px, 98px, 0);
  }

  50% {
    clip: rect(61px, 9999px, 85px, 0);
  }

  60% {
    clip: rect(99px, 9999px, 114px, 0);
  }

  70% {
    clip: rect(34px, 9999px, 115px, 0);
  }

  80% {
    clip: rect(98px, 9999px, 129px, 0);
  }

  90% {
    clip: rect(43px, 9999px, 96px, 0);
  }

  100% {
    clip: rect(82px, 9999px, 64px, 0);
  }
}

.parallax-section {
  position: relative;
  height: 300px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.parallax-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./../public/images/bg.png');
  background-size: cover;
  background-position: center;
  transform: translateZ(-1px) scale(2);
  z-index: -1;
}

.parallax-content {
  background-color: rgba(var(--bg-secondary), 0.8);
  padding: 20px;
  border-radius: 8px;
  text-align: center;
}

.scroll-indicator {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background-color: var(--accent-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}

.scroll-indicator.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-indicator:hover {
  transform: translateY(-3px);
}

.scroll-indicator::before {
  content: '↑';
  color: var(--bg-primary);
  font-size: 20px;
}

.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-primary);
  transition: color 0.3s ease;
}

.theme-toggle:hover {
  color: var(--accent-primary);
}

.tag {
  background-color: var(--accent-primary);
  color: var(--bg-primary);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  font-weight: 600;
  display: inline-block;
  margin: 2px;
  transition: all 0.3s ease;
}

.tag:hover {
  background-color: var(--accent-secondary);
}

.flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.space-y-6>*+* {
  margin-top: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.max-w-2xl {
  max-width: 42rem;
}

.w-full {
  width: 100%;
}

.sm\:w-auto {
  width: auto;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .skill-grid {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }

  .blockchain-visualization {
    flex-direction: column;
    align-items: flex-start;
  }

  .block {
    margin-bottom: 20px;
  }

  .block::after {
    content: '↓';
    right: 50%;
    bottom: -30px;
    top: auto;
    transform: translateX(50%);
  }
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation: none !important;
    transition: none !important;
  }
}